import React from 'react'

import IcomoonReact from 'icomoon-react'
import iconSet from '../../../images/icons/selection.json'

import styles from './hamburger.module.css'

const Hamburger = ({ buttonClick, content }) => {
  return (
    <div className={ styles.Wrapper } >
      <button onClick={ buttonClick } >
        <span style={{ fontSize: '0' }} >Hamburger</span>
        {
          content ?
          <IcomoonReact iconSet={ iconSet } icon='close-button' alt='Fechar menu' className={ styles.Hamburger } /> :
          <IcomoonReact iconSet={ iconSet } icon='hamburger' alt='Abrir menu' className={ styles.Hamburger } />
        }
      </button>
    </div>
  )
}

export default Hamburger
