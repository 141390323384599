import React, { useContext } from 'react'
import { Link } from 'gatsby'
import t from 'prop-types'

import styles from './button.module.css'

import ModalContext from '../../context/ModalContext'

const Button = ({ external, modal, destination, children, lg, md, ...rest }) => {

  const modalToggler = useContext(ModalContext)

  const btnSize = () => {
    if( lg ) {
      return styles.Large
    }

    if( md ) {
      return styles.Medium
    }

    return styles.Small
  }

  if (external) {
    return <a href={ destination } ><div className={ styles.Button + ' ' + btnSize() } {...rest} >{ children }</div></a>
  } else if (modal) {
    return <div className={ styles.Button + ' ' + btnSize() } onClick={ () => modalToggler.setShowModal(true) } onKeyDown={ () => modalToggler.setShowModal(true) } role='button' tabIndex='0' {...rest} >{ children }</div>
  }
  return <Link to={ destination } ><div className={ styles.Button + ' ' + btnSize() } {...rest} >{ children }</div></Link>
}


Button.propTypes = {
  external: t.bool,
  modal: t.bool,
  destination: t.string,
  children: t.any,
  lg: t.bool,
  md: t.bool,
}

Button.defaultProps = {
  external: false,
  modal: false,
  destination: '/',
  children: 'Lorem Ipsum',
  lg: false,
  md: false,
}

export default Button
