import React from 'react'

import styles from './mobileMenu.module.css'

import Button from '../../Button/Button'
import NavbarLinks from '../NavbarLinks/NavbarLinks'

const MobileMenu = ({ closeMenu }) => {

  const handleClick = (event) => {
    if(event.target.tagName === 'A') {
      return closeMenu(false)
    }
  }

  return (
    <div className={ styles.Menu } onClick={ (e) => handleClick(e) } onKeyDown={ (e) => handleClick(e) } role='button' tabIndex='0' >
      <NavbarLinks />
      <Button modal md >Fale conosco</Button>
    </div>
  )
}

MobileMenu.propTypes = {

}

export default MobileMenu
