import React from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import styles from './footer.module.css'

import Layout from '../../hoc/Layout/Layout'

import Img from 'gatsby-image'

import Button from '../Button/Button'
import FooterLinks from './FooterLinks/FooterLinks'
import SocialMedia from './SocialMedia/SocialMedia'

const Footer = () => {

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        desktop: childImageSharp {
          fixed(height: 97) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const getYear = new Date()

  return (
    <footer className={ styles.Wrapper } >
      <Layout innerContainer={{ paddingTop: '4rem', paddingBottom: '4rem' }} >
        <div className={ styles.TopSection } >
          <span>A sua cozinha no nosso ambiente pode ser incrível.<br className='HiddenMobile' /> Quer saber melhor como funciona?</span>
          <Button modal md >Fale conosco</Button>
        </div>
        <hr className={ styles.Line } />
        <div className={ styles.Content } >
          <Link to='/' >
            <Img fixed={ data.placeholderImage.desktop.fixed } style={{ marginBottom: '3rem' }}  alt='Homepage' />
          </Link>
          <div className={ styles.Links } >
            <FooterLinks
              links = {[
                {
                  destination: '/contato/#faq',
                  text: 'Perguntas Frequentes',
                },
                {
                  destination: '/contato/#formulario-de-contato',
                  text: 'Contato',
                },
              ]}
            />
            <SocialMedia />
          </div>
        </div>
      </Layout>
      <div className={ styles.Copyright } >Copyright Kitchenfy { getYear.getFullYear() }</div>
    </footer>
  )
}

export default Footer
