import React from 'react'
import t from 'prop-types'

import IcomoonReact from 'icomoon-react'
import iconSet from '../../../images/icons/selection.json'

import styles from './socialMedia.module.css'

const SocialMedia = ({ socials }) => {
  return (
    <div className={ styles.Wrapper } >
      <div className={ styles.Icons } >
        {
          socials.map((social, index) => {
            return (
              <a href={ social.destination } target='_blank' key={ index } rel='noopener noreferrer' >
                <span style={{ fontSize: '0' }} >{ social.icon }</span>
                <IcomoonReact iconSet={ iconSet } icon={ social.icon } className={ styles.Icon } alt={ social.icon } />
              </a>
            )
          })
        }
      </div>
    </div>
  )
}

SocialMedia.propTypes = {
  socials: t.arrayOf(t.objectOf(t.string)),
}

SocialMedia.defaultProps = {
  socials: [
    {
      destination: 'https://www.facebook.com/kitchenfy.brasil',
      icon: 'facebook',
    },
    {
      destination: 'https://www.instagram.com/kitchenfy.brasil/',
      icon: 'instagram',
    },
    {
      destination: 'https://wa.me/5527997349965',
      icon: 'whatsapp',
    },
    {
      destination: 'mailto:atendimento@kitchenfy.com.br',
      icon: 'mail',
    },
  ]
}

export default SocialMedia
