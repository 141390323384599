import React from 'react'
import t from 'prop-types'

import styles from './dashTitle.module.css'

const DashTitle = ({ children, dashColor, subText }) => {
  return (
    <div className={ styles.Wrapper } >
      <h1>{children}</h1>
      <div className={ styles.Dash } style={{ backgroundColor: dashColor }} />
      { subText ? <p>{ subText }</p> : null }
    </div>
  )
}

DashTitle.propTypes = {
  children: t.string,
  dashColor: t.string,
  subText: t.any,
}

DashTitle.defaultProps = {
  children: 'Lorem Ipsum',
  dashColor: '',
}

export default DashTitle
