import React from 'react'
import t from 'prop-types'

import { Link } from 'gatsby'

import styles from './footerLinks.module.css'

const FooterLinks = ({ links }) => {
  return (
    <div className={ styles.Wrapper } >
      {
        links.map((link, index) => {
          if(link.external) {
            return <a href={ link.destination } target='_blank' rel="noreferrer" key={ index } >{ link.text }</a>
          }
          return <Link to={`${ link.destination }`} key={ index } >{ link.text }</Link>
        })
      }
    </div>
  )
}

FooterLinks.propTypes = {
  links: t.arrayOf(t.object)
}

FooterLinks.defaultProps = {
  links: [
    {
      destination: '',
      text: 'Lorem Ipsum',
      external: false,
    },
    {
      destination: '',
      text: 'Lorem Ipsum',
      external: false,
    },
    {
      destination: 'https://ciro.design/',
      text: 'Lorem Ipsum',
      external: true,
    },
    {
      destination: 'https://ciro.design/',
      text: 'Lorem Ipsum',
      external: true,
    },
  ]
}

export default FooterLinks
