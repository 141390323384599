import React from 'react'

import styles from './GDPRModal.module.css'

const GDPRModal = ({ onClick }) => {
  return (
    <div className={ styles.Wrapper } >
      <p>Este site utiliza cookies para melhor sua experiência de uso.</p>
      <div>
        <button onClick={ () => onClick('true') } >Aceitar</button>
        <button onClick={ () => onClick('false') } >Rejeitar</button>
      </div>
    </div>
  )
}

export default GDPRModal
