import React from 'react'
import t from 'prop-types'

import InputMask from 'react-input-mask'

import styles from './input.module.css'

const Input = ({ fullWidth, name, label, placeholder, invalid, errorMessage, mask, ...rest }) => {

  const invalidStyle = {
    borderBottom: '3px solid var(--light-red)',
  }

  return (
    <div className={ styles.Wrapper } style={ fullWidth ? { gridColumnStart: '1', gridColumnEnd: '3' } : null } >
      <label htmlFor={ name } >{ label }
        <InputMask name={ name } id={ name } placeholder={ placeholder } mask={ mask } style={ invalid ? invalidStyle : null } {...rest} />
        { invalid ? <div>{ errorMessage }</div> : null }
      </label>
    </div>
  )
}

Input.propTypes = {
  fullWidth: t.bool,
  name: t.string,
  label: t.string,
  placeholder: t.string,
  invalid: t.bool,
  errorMessage: t.string,
}

Input.defaultProps = {
  fullWidth: false,
  name: 'lorem_ipsum',
  label: 'Lorem Ipsum',
  placeholder: 'Lorem Ipsum',
  invalid: false,
  errorMessage: 'Lorem Ipsum',
}

export default Input
