import React from 'react'
import { Link } from 'gatsby'

import styles from './navbarLinks.module.css'

const NavbarLinks = () => {
  return (
    <div className={ styles.NavbarLink }>
      <Link to='/#beneficios'>Benefícios</Link>
      <Link to='/contato/#faq'>Perguntas Frequentes</Link>
      <Link to='/contato/#formulario-de-contato'>Contato</Link>
    </div>
  )
}

export default NavbarLinks
