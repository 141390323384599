import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql, Link } from "gatsby"

import styles from './navbar.module.css'

import Img from 'gatsby-image'

import useWindowDimensions from '../../hooks/useWindowDimensions'

import Button from '../../components/Button/Button'
import Hamburger from './Hamburger/Hamburger'
import MobileMenu from './MobileMenu/MobileMenu'
import NavbarLinks from './NavbarLinks/NavbarLinks'
import Layout from '../../hoc/Layout/Layout'

const Navbar = () => {

  const windowDimensions = useWindowDimensions();
  const [ mobile, setMobile ] = useState(false)
  const [ showContent, setShowContent ] = useState(false)

  useEffect(() => {
    if(windowDimensions.width <= 1024 ) {
      return setMobile(true)
    }
    return setMobile(false)
  }, [windowDimensions.width])

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "logo.png" }) {
        desktop: childImageSharp {
          fixed(height: 60) {
            ...GatsbyImageSharpFixed
          }
        }
        mobile: childImageSharp {
          fixed(height: 46) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <>
      <Layout
        innerContainer={mobile ?
          { height: '75px', display: 'flex', alignItens: 'center', justifyContent: 'center', padding: '0', flexDirection: 'row' } :
          { height: '10vh', display: 'flex', alignItens: 'center', justifyContent: 'space-between', padding: '0', minHeight: '80px' }}
        outerContainer={{ margin: 0, boxShadow: '0 0 0.5em var(--light-gray)', background: 'white', zIndex: '500', position: 'fixed', width: '100%' }}
      >
        <Link className={ styles.Align } to='/' onClick={ () => setShowContent(false) } >
          <Img fixed={ mobile ? data.placeholderImage.mobile.fixed : data.placeholderImage.desktop.fixed } alt='Homepage' />
        </Link>
          {mobile ?
            <Hamburger buttonClick={ () => setShowContent(!showContent) } content={ showContent } /> :
            <div className={ styles.Align } >
              <NavbarLinks />
              <Button modal >Fale Conosco</Button>
            </div>
          }
      </Layout>
      {showContent && mobile ? <MobileMenu closeMenu={ () => setShowContent() } /> : null }
    </>
  )
}

export default Navbar
