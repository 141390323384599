import React, { useState, useEffect } from 'react'

import './mainWrapper.css'

import ModalContext from '../../context/ModalContext'

import DashTitle from '../../components/DashTitle/DashTitle'
import FormFaleConosco from '../../components/Forms/FormFaleConosco'
import GDPRModal from '../../components/GDPRModal/GDPRModal'
import Modal from '../../components/Modal/Modal'
import Navbar from '../../components/Navbar/Navbar'
import Footer from '../../components/Footer/Footer'

const MainWrapper = ({ children }) => {

  const [showModal, setShowModal] = useState(false)
  const [showGDPRModal, setShowGDPRModal] = useState(false)

  const setCookies = ( value ) => {
    document.cookie = `gdpr-option-chosen=${ value };`
    document.cookie = `gatsby-gdpr-google-analytics=${ value };`
    document.cookie = `gatsby-gdpr-google-tagmanager=${ value };`
    setShowGDPRModal(false)
  }

  useEffect(() => {
    const documentCookies = document.cookie
    if ( !documentCookies.includes('gatsby-gdpr-google-analytics') ) { document.cookie = 'gatsby-gdpr-google-analytics=false;' }
    if ( !documentCookies.includes('gatsby-gdpr-google-tagmanager') ) { document.cookie = 'gatsby-gdpr-google-tagmanager=false;' }
    if ( !documentCookies.includes('gdpr-option-chosen') ) { setShowGDPRModal(true) }
  }, [])

  return (
    <>
    <ModalContext.Provider value={{ showModal, setShowModal }} >
      <Navbar />
        <main>
          { showModal ?
            <Modal>
              <DashTitle>Fale Conosco</DashTitle>
              <FormFaleConosco />
            </Modal>
            : null }
          { showGDPRModal ? <GDPRModal onClick={ setCookies } /> : null }
          { children }
        </main>
      <Footer />
    </ModalContext.Provider>
    </>
  )
}

export default MainWrapper

